import cookies from 'js-cookie';

const COOKIE_NAME = 'cookie_consent_status';

const getPersistedConsentStatus = () => cookies.get(COOKIE_NAME);

const persistConsentStatus = ({ status, expires }) => {
  cookies.set(COOKIE_NAME, status, { expires: expires });
};

const unpersistConsentStatus = () => {
  cookies.remove(COOKIE_NAME);
};

export {
  getPersistedConsentStatus,
  persistConsentStatus,
  unpersistConsentStatus,
};
