import cookies from 'js-cookie';

const COOKIE_NAME = 'cookie_consent_allowances';
const DELIMINATATOR = ';';

const AllAllowances = {
  Convienences: ['Facebook', 'Youtube', 'Vimeo'],
  Analytics: [
    'Facebook Pixel',
    'Google Anaytics',
    'Google Maps',
    'AdAnt Media Werbeerfolgsmessung',
  ],
};

const getPersistedConsentAllowances = () => {
  const strinfifiedAllowances = cookies.get(COOKIE_NAME);

  return strinfifiedAllowances && strinfifiedAllowances.split(DELIMINATATOR);
};

const persistConsentAllowances = ({ allowances, expires }) => {
  const strinfifiedAllowances = allowances.join(DELIMINATATOR);

  cookies.set(COOKIE_NAME, strinfifiedAllowances, { expires: expires });
};

const unpersistConsentAllowances = () => {
  cookies.remove(COOKIE_NAME);
};

export {
  getPersistedConsentAllowances,
  persistConsentAllowances,
  unpersistConsentAllowances,
  AllAllowances,
};
