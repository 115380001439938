const BLOCKER_NAME = 'cookie_consent_blocker';
const BLOCKER_STATUS_ENABLED = 'cookie_consent_blocker_enabled';

const BLOCKER_CSS = `
  .cookie_consent_blocker_enabled {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2147483646;
    background-color:#fff;
    opacity: .8;
    transition: opacity .25s;
  }
`;
const injectStyleSheet = (css) => {
  const styleEl = document.createElement('style');

  styleEl.type = 'text/css';

  if (styleEl.styleSheet) styleEl.styleSheet.cssText = css;
  else styleEl.appendChild(document.createTextNode(css));

  document.body.appendChild(styleEl);
};

const createBlocker = () => {
  injectStyleSheet(BLOCKER_CSS);
  const blockerEl = document.createElement('div');

  blockerEl.setAttribute('id', BLOCKER_NAME);
  document.body.appendChild(blockerEl);
};

const removeBlocker = () => {
  const blockerEl = document.getElementById('element-id');

  blockerEl && blockerEl.parentNode.removeChild(blockerEl);
};

const enableBlocker = () => {
  const blockerEl = document.getElementById(BLOCKER_NAME);

  blockerEl && blockerEl.classList.add(BLOCKER_STATUS_ENABLED);
};

const disableBlocker = () => {
  const blockerEl = document.getElementById(BLOCKER_NAME);

  blockerEl && blockerEl.classList.remove(BLOCKER_STATUS_ENABLED);
};

export { createBlocker, enableBlocker, disableBlocker };
