import { unpersistConsentAllowances } from './consent-cookie-allowances';
import { unpersistConsentStatus } from './consent-cookie-status';

const CONFIGURATION_PROP_NAME = '__CONSENT_SLIDE_OUT_CONFIGURATION__';

const initialConfiguration = {
  disclaimerLink: '/disclaimer',
};

const initialize = () => {
  window[CONFIGURATION_PROP_NAME] = { ...initialConfiguration };
};

const configure = (nextConfiguration) => {
  window[CONFIGURATION_PROP_NAME] = {
    ...window[CONFIGURATION_PROP_NAME],
    ...nextConfiguration,
  };
};
const reset = () => {
  unpersistConsentAllowances();
  unpersistConsentStatus();
};

const expose = () => {
  window.consentSlideOut = {
    configure,
    reset,
  };
};

const get = (propName) => {
  const configuration = window[CONFIGURATION_PROP_NAME];

  return configuration && configuration[propName];
};

export { initialize, configure, expose, get };
