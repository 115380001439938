import { h, render } from 'preact';
import {
  initialize as initializeConfiguration,
  expose as exposeConfiguration,
  configure,
} from './configuration';
import {
  getPersistedConsentStatus,
  unpersistConsentStatus,
} from './consent-cookie-status';
import ConsentSlideOut from './consent-slide-out';

const triggerBannerName = 'cookie-consent-banner';
const slideOutElName = 'cookie-consent-side-out';

const createSlideOutEl = () => {
  const slideOutEl = document.createElement('div');

  slideOutEl.setAttribute('id', slideOutElName);
  slideOutEl.setAttribute(
    'style',
    'position: fixed; bottom: 0; z-index: 2147483647; width: 100%'
  );

  document.body.appendChild(slideOutEl);
};

initializeConfiguration();
exposeConfiguration();

window.addEventListener('load', () => {
  const constentStatus = getPersistedConsentStatus();

  createSlideOutEl();

  const bannerEl =
    window.document.getElementById(triggerBannerName) ||
    window.document.querySelector(`.${triggerBannerName}`);
  const slideOutEl = window.document.getElementById(slideOutElName);

  if (bannerEl) {
    bannerEl.addEventListener('click', (e) => {
      e.stopPropagation();

      unpersistConsentStatus();

      render(<ConsentSlideOut />, slideOutEl);
    });
  }

  if (constentStatus) {
    return;
  }

  render(<ConsentSlideOut />, slideOutEl);
});
