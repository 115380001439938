import { h, Component, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import getIsMobile from 'ismobilejs';
import { get as getConfiguration } from './configuration';
import messages from './i18n/de.json';
import { persistConsentStatus } from './consent-cookie-status';
import {
  getPersistedConsentAllowances,
  unpersistConsentAllowances,
  persistConsentAllowances,
  AllAllowances,
} from './consent-cookie-allowances';
import {
  createBlocker,
  enableBlocker,
  disableBlocker,
} from './interactions-blocker';

const Status = {
  NotAnswered: 'NotAnswered',
  Accepted: 'Accepted',
  Denied: 'Denied',
  OptingIn: 'OptingIn',
};

const getWrapperStyles = () => ({});
const getBannerStyles = ({ isMobile }) => ({
  display: 'flex',
  'justify-content': 'space-between',
  padding: '1em',
  'font-family': 'Verdana, Sans-Serif',
  color: '#fff',
  background: 'rgba(0,0,0,.7)',
  color: '#fafafa',
  ...(isMobile && { flexFlow: 'column' }),
});
const getTextStyles = ({ isForm } = {}) => ({
  'align-self': 'center',
  color: '#fff',
  ...(isForm && { padding: '0' }),
  ...(isForm && { margin: '.5em 1.75em' }),
});
const getTextColorStyles = () => ({
  color: '#fff',
});
const getListStyles = ({ isForm, isFirstLevel } = {}) => ({
  'align-self': 'center',
  ...(isForm ? { 'list-style-type': 'none' } : { 'list-style-type': 'circle' }),
  color: '#fff',
  ...(isForm && { margin: '0' }),
  ...(!isForm && { margin: '0 2em' }),
  ...(isForm && isFirstLevel && { margin: '.5em 0', padding: '0' }),
  ...(isForm && !isFirstLevel && { padding: '.5em 1.75em' }),
});
const getDescriptionsStyles = ({ isMobile }) => ({
  //...(!isMobile && { flex: '1 1 50em' }),
  color: '#fff',
});
const getOptingInOptionsStyles = () => ({
  display: 'flex',
  'justify-content': 'space-between',
  'flex-direction': 'row',
});
const getOptingInDescriptionStyles = () => ({
  margin: '0 0 1em 0',
  padding: 0,
  'font-size': '85%',
});
const getLinksStyles = ({ isMobile }) => ({
  display: 'flex',
  'flex-flow': 'column',
  'justify-content': 'center',
  whitespace: 'nowrap',
  opacity: 0.8,
  color: '#fff',
  'font-size': '85%',
  ...(!isMobile && { padding: '0 3em' }),
});
const getActionStyles = ({ isMobile }) => ({
  display: 'flex',
  margin: '1em 0',
  'align-items': 'flex-start',
  ...(isMobile && {
    'flex-direction': 'row',
    'justify-content': 'space-between',
  }),
  ...(!isMobile && { flex: '0 1 70em' }),
});
const getButtonsStyles = ({ isMobile }) => ({
  'max-width': '15em',
});
const getLinkStyles = ({ isDelay }) => ({
  color: 'inherit',
  ...(isDelay && { 'font-weight': 'bold' }),
});
const getAcceptStyles = ({ isMobile }) => ({
  'font-family': 'Verdana, Sans-Serif',
  display: 'inline-block',
  'font-weight': 'bold',
  'min-width': '15em',
  'max-height': '4em',
  'min-height': '4em',
  padding: '0 2em',
  background: '#38e',
  'border-radius': '4px',
  cursor: 'pointer',
  color: '#fff',
  'font-size': '100%',
});
const getOptingInStyles = ({ isMobile, isOptingOut }) => ({
  display: 'flex',
  'align-items': 'center',
  'align-self': 'center',
  'max-height': '3em',
  'min-height': '3em',
  padding: '0 2em',
  margin: '0 1em 0 0',
  'border-radius': '4px',
  'text-transform': 'uppercase',
  cursor: 'pointer',
  color: '#fff',
  'font-size': '80%',
  ...(!isOptingOut && { background: '#38e' }),
  ...(isOptingOut && { background: 'inherit' }),
});

const useConsentAllowances = () => {
  const [allowances, setAllowances] = useState(
    () => getPersistedConsentAllowances() || []
  );
  const toggleAllowances = (allowancesToToggle) => {
    let nextAllowances = [].concat(allowances);

    allowancesToToggle.forEach((allowanceToToggle) => {
      if (!allowances.includes(allowanceToToggle)) {
        nextAllowances = nextAllowances.concat(allowanceToToggle);
      } else {
        nextAllowances = nextAllowances.filter(
          (previousAllowance) => previousAllowance !== allowanceToToggle
        );
      }
    });

    setAllowances(nextAllowances);
  };

  return [allowances, toggleAllowances];
};
const ConstentAllowances = ({ allowances, toggleAllowances }) => {
  const allowanceGroups = Object.keys(AllAllowances);

  return (
    <ul style={getListStyles({ isForm: true, isFirstLevel: true })}>
      <li style={{ padding: '.5em 0' }}>
        <input
          type="checkbox"
          id="technial"
          name="technial"
          value="technial"
          checked
          disabled
        />
        <label for="technial">
          &nbsp;
          {messages.allowanceGroupTitleTechnicallyRequired}
        </label>
        <p style={getTextStyles({ isForm: true })}>
          {messages.allowanceGroupDescriptionTechnicallyRequired}
        </p>
      </li>
      {allowanceGroups.map((allowanceGroup) => {
        const allowancesOfGroup = AllAllowances[allowanceGroup];

        return (
          <li style={{ padding: '.5em 0' }}>
            <input
              type="checkbox"
              id={allowanceGroup}
              name={allowanceGroup}
              value={allowanceGroup}
              checked={allowancesOfGroup.every((allowanceGroup) =>
                allowances.includes(allowanceGroup)
              )}
              onClick={() => {
                toggleAllowances(allowancesOfGroup);
              }}
            />
            <label for={allowanceGroup}>
              &nbsp;
              {messages[`allowanceGroupTitle${allowanceGroup}`]}
            </label>
            <p style={getTextStyles({ isForm: true })}>
              {messages[`allowanceGroupDescription${allowanceGroup}`]}
            </p>

            <ul style={getListStyles({ isForm: true })}>
              {allowancesOfGroup.map((allowanceOfGroup) => (
                <li>
                  <input
                    type="checkbox"
                    id={allowanceOfGroup}
                    name={allowanceOfGroup}
                    value={allowanceOfGroup}
                    checked={allowances.includes(allowanceOfGroup)}
                    onClick={() => {
                      toggleAllowances([allowanceOfGroup]);
                    }}
                  />
                  <label for={allowanceOfGroup}>&nbsp;{allowanceOfGroup}</label>
                </li>
              ))}
            </ul>
          </li>
        );
      })}
    </ul>
  );
};

const ConsentSlideOut = (props) => {
  const isMobile = getIsMobile().any;
  const [status, setStatus] = useState(Status.NotAnswered);
  const [
    areConsentAllowancesVisible,
    setConsentAllowancesVisiblility,
  ] = useState(false);
  const [allowances, toggleAllowances] = useConsentAllowances();

  useEffect(() => {
    createBlocker();
    enableBlocker();

    return () => removeBlocker();
  }, []);

  const hasChoosenMinimalAllowances =
    allowances.length === 0 && areConsentAllowancesVisible;
  const hasChoosenSomeAllowances =
    allowances.length > 0 && areConsentAllowancesVisible;
  const hasChoosenAllAllowances = !areConsentAllowancesVisible;
  const hasStatus = (expectedStatus) => expectedStatus === status;

  const handleAccept = () => {
    persistConsentStatus({ status: Status.Accepted, expires: 30 });
    setStatus(Status.Accepted);

    if (!hasChoosenAllAllowances) {
      persistConsentAllowances({ allowances, expires: 365 });
    }

    disableBlocker();
  };

  const handleOptingIn = () => {
    setStatus(Status.OptingIn);
  };

  const handleOptOut = () => {
    setStatus(Status.NotAnswered);
  };

  const handleDelay = () => {
    persistConsentStatus({ status: Status.Accepted, expires: 1 });
    setStatus(Status.Accepted);
    unpersistConsentAllowances();

    disableBlocker();
  };

  const handleDeny = () => {
    persistConsentStatus({ status: Status.Denied });
    setStatus(Status.Denied);
    unpersistConsentAllowances();

    disableBlocker();
  };

  const handleShowConsentAllowances = () => {
    setConsentAllowancesVisiblility(true);
  };

  const handleHideConsentAllowances = () => {
    setConsentAllowancesVisiblility(false);
  };

  const hasMadeChoice = hasStatus(Status.Accepted) || hasStatus(Status.Denied);

  return (
    <div style={getWrapperStyles()}>
      {!hasMadeChoice && (
        <div style={getBannerStyles({ isMobile })}>
          <div style={getDescriptionsStyles({ isMobile })}>
            <span style={getTextStyles()}>
              <p style={getTextColorStyles()}>{messages.description1}</p>
              <p style={getTextColorStyles()}>{messages.description2}</p>
            </span>
            {areConsentAllowancesVisible && (
              <ConstentAllowances
                allowances={allowances}
                toggleAllowances={toggleAllowances}
              />
            )}
          </div>
          <div style={getActionStyles({ isMobile })}>
            <span style={getLinksStyles({ isMobile })}>
              <a
                style={getLinkStyles({ isDelay: false })}
                target="newPricayPolicy"
                href={getConfiguration('disclaimerLink')}
              >
                {messages.disclaimerTitle}
              </a>
              <a
                style={getLinkStyles({ isDelay: false })}
                href="#"
                onClick={
                  areConsentAllowancesVisible
                    ? handleHideConsentAllowances
                    : handleShowConsentAllowances
                }
              >
                {areConsentAllowancesVisible
                  ? messages.hideConsentAllowances
                  : messages.showConsentAllowances}
              </a>
              {!areConsentAllowancesVisible && (
                <a
                  style={getLinkStyles({ isDelay: true })}
                  href="#"
                  onClick={handleDelay}
                >
                  {messages.delay}
                </a>
              )}
            </span>
            <span style={getButtonsStyles({ isMobile })}>
              {(() => {
                if (hasStatus(Status.NotAnswered))
                  return (
                    <button
                      style={getAcceptStyles({ isMobile })}
                      onClick={handleOptingIn}
                    >
                      {messages.accept}
                    </button>
                  );
                else if (hasStatus(Status.OptingIn))
                  return (
                    <Fragment>
                      <p style={getOptingInDescriptionStyles({ isMobile })}>
                        {(hasChoosenMinimalAllowances &&
                          messages.optInMinimalDescription) ||
                          (hasChoosenSomeAllowances &&
                            messages.optInSomeDescription) ||
                          (hasChoosenAllAllowances &&
                            messages.optInAllDescription)}
                      </p>
                      <div style={getOptingInOptionsStyles({ isMobile })}>
                        <button
                          style={getOptingInStyles({ isMobile })}
                          onClick={handleAccept}
                        >
                          {messages.optIn}
                        </button>
                        <button
                          style={getOptingInStyles({
                            isMobile,
                            isOptingOut: true,
                          })}
                          onClick={handleOptOut}
                        >
                          {messages.optOut}
                        </button>
                      </div>
                    </Fragment>
                  );
              })()}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConsentSlideOut;
